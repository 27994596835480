/**
 * Class element
 * Accordion Member component
 * Display an accordion with a picture and infos of team member
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState} from "react-accessible-accordion"
import { IconContext } from "react-icons"
import { BsChevronDown, BsCheck } from "react-icons/bs"
import "../styles/components/accordionmember.scss"

/* Declaration class */
class Accordionmember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexActive: null
    }
  }

  onChangeAccordion(index) {
    if(index === this.state.indexActive) {
      index = null
    }
    this.setState({
      indexActive: index
    })
  }

  render () {
    const arrItems = this.props.arrItems
    return (
      <Accordion className="accordion accordion-member" allowZeroExpanded>
        {arrItems.map((item, index) => (
          <AccordionItem key={`accordion-${item.name}-${index}`} uuid={item.index} className={(item.index === this.state.indexActive) ? "accordion__item active-accordion":"accordion__item"} onClick={() => this.onChangeAccordion(index)}>
            <AccordionItemHeading>
              <AccordionItemButton >
                <div className="box-flex-title">
                  <GatsbyImage image={item.picture.localFile.childImageSharp.gatsbyImageData} alt={`Hello Health's US support member: ${item.name}`} />
                  <div className="titles">
                    <h3 dangerouslySetInnerHTML={{__html:item.name}} />
                    <h4 dangerouslySetInnerHTML={{__html:item.title}} />
                  </div>
                </div>
                <AccordionItemState>
                  {({ expanded }) =>  (expanded ?
                    (<span className="span-chevron active-accordion">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsChevronDown />
                      </IconContext.Provider>
                    </span>) :
                    (<span className="span-chevron">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsChevronDown />
                      </IconContext.Provider>
                    </span>))}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <ul className="box-list-accordion">
                {item.arrbio.map( (bio, index) =>
                  <li key={index}>
                    <span className="span-check">
                      <IconContext.Provider value={{ className: "icon-check" }}>
                        <BsCheck />
                      </IconContext.Provider>
                    </span>
                    <span>{bio.itemBioMember}</span>
                  </li>
                )}
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          )
        )}
      </Accordion>
    )
  }
}



/* Export function */
export default Accordionmember

